import Toast from 'components/common/notifications/Toast';
import {
  CONTROLLER_QUERY_KEY,
  useBatchUpdateControllersMutation,
  useControllerQuery,
  useUpdateControllerMutation,
} from '../reactQuery/controller/controllerQuery';
import useControllerStore from 'store/controllerStore';
import useFarmStore from 'store/farmStore';
import useTranslate from '../useTranslate';
import { Language } from 'types/types';
import { ACTION_TYPES } from 'constants/action';
import { useQueryClient } from '@tanstack/react-query';

export function useController() {
  const t = useTranslate();
  const { currentFarmNumber: farmNumber, access } = useFarmStore();
  const { currentControllerNumber: controllerNumber } = useControllerStore();

  const { isLoading, data } = useControllerQuery(
    farmNumber,
    controllerNumber,
    access,
  );
  const { mutateAsync: updateControllerMutate } = useUpdateControllerMutation(
    farmNumber,
    controllerNumber,
  );

  const updateController = async (action: string, value: number) => {
    const data = await updateControllerMutate({
      payload: { farmNumber, controllerNumber, action, value },
      access,
    });
    if (data?.isSuccess) {
      if (data.code[0] === 1003) {
        Toast.fire({
          icon: 'success',
          title: t('자동모드로 전환합니다.'),
        });
      } else if (data.code[0] === 1004) {
        Toast.fire({
          icon: 'success',
          title: t('수동모드로 전환합니다.'),
        });
      } else if (data.code[0] === 1017) {
        Toast.fire({
          icon: 'success',
          title: t('시작시간이 변경되었습니다.'),
        });
      } else if (data.code[0] === 1017 || data.code[0] === 1018) {
        //언어설정 변경의 경우 메세지를 띄우지 않음
        return;
      } else {
        Toast.fire({
          icon: 'success',
          title: t('message_change_success'),
        });
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: t('message_change_fail'),
      });
    }
  };

  const updateLanguage = async (language: Language) => {
    try {
      const data = await updateControllerMutate({
        payload: {
          farmNumber,
          controllerNumber,
          action: ACTION_TYPES.LANGUAGE_CHANGE,
          value: language,
        },
        access,
      });
      if (data?.isSuccess) {
      }
    } catch (e) {
      console.error(e);
    }
  };

  const { mutateAsync: batchUpdateControllerMutateAsync } =
    useBatchUpdateControllersMutation(farmNumber, controllerNumber);

  const batchUpdateControllers = async (
    actions: string[],
    values: number[],
  ) => {
    const data = await batchUpdateControllerMutateAsync({
      payload: { farmNumber, controllerNumber, actions, values },
      access,
    });
    if (data?.isSuccess) {
      Toast.fire({
        icon: 'success',
        title: t('message_change_success'),
      });
    } else {
      Toast.fire({ icon: 'error', title: t('message_change_fail') });
    }
  };

  return {
    controller: data?.controller ? data?.controller[0] : null,
    updateController,
    updateLanguage,
    batchUpdateControllers,
    isLoading,
    isAuto: data?.controller ? data?.controller[0].is_auto : null,
  };
}
