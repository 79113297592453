import React from 'react';
import { IcProps } from '.';

export const IcArrowCycle: React.FC<IcProps> = ({
  width = 12,
  height = 13,
  color = '#7D7B7B',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
    >
      <g clipPath="url(#clip0_2758_14558)">
        <path
          d="M11 6.5C11 9.5 8.805 11.5 6.097 11.5C3.896 11.5 2.12 10.3325 1.5 8.5M1 6.5C1 3.5 3.195 1.5 5.9035 1.5C8.104 1.5 9.879 2.6675 10.5 4.5"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 9L1.5 8.5L1 10.5M8.5 4L10.5 4.5L11 2.5"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2758_14558">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
