import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  requestBatchUpdateControllers,
  requestController,
  requestUpdateController,
} from 'api/controller/controller';

export const CONTROLLER_QUERY_KEY = 'controller';

// 컨트롤러 데이터를 쿼리하는 커스텀 훅
export const useControllerQuery = (
  farmNumber: number,
  controllerNumber: number,
  access: number,
) => {
  return useQuery({
    queryKey: [CONTROLLER_QUERY_KEY, farmNumber, controllerNumber], // 컨트롤러 데이터와 id를 기반으로 한 고유한 쿼리 키
    queryFn: async () =>
      await requestController({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 30 * 1000, // 30초
    refetchInterval: 30 * 1000,
    cacheTime: 3 * 60 * 1000, // 3분
  });
};

export const useUpdateControllerMutation = (
  farmNumber: number,
  controllerNumber: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestUpdateController,
    onSuccess: () => {
      queryClient.invalidateQueries([
        CONTROLLER_QUERY_KEY,
        farmNumber,
        controllerNumber,
      ]);
    },
  });
};

export const useBatchUpdateControllersMutation = (
  farmNumber: number,
  controllerNumber: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestBatchUpdateControllers,
    onSuccess: () => {
      queryClient.invalidateQueries([
        CONTROLLER_QUERY_KEY,
        farmNumber,
        controllerNumber,
      ]);
    },
  });
};
