import { useQuery } from '@tanstack/react-query';
import {
  requestHumidityStatistics,
  requestHumidityStatisticsWeek,
} from 'api/humidity';
import useFarmStore from 'store/farmStore';

export const HUMIDITY_QUERY_KEY = 'humidity';
export const HUMIDITY_WEEK_QUERY_KEY = 'humidity_week';
export const useHumidityStatisticQuery = (controllerNumber: number) => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();

  return useQuery({
    queryKey: [HUMIDITY_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestHumidityStatistics({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 30 * 1000, //30초
    refetchInterval: 30 * 1000,
    cacheTime: 10 * 60 * 1000, //10분
  });
};

export const useHumidityStatisticWeekQuery = (controllerNumber: number) => {
  const { currentFarmNumber: farmNumber, access } = useFarmStore();

  return useQuery({
    queryKey: [HUMIDITY_WEEK_QUERY_KEY, farmNumber, controllerNumber],
    queryFn: async () =>
      await requestHumidityStatisticsWeek({
        payload: { farmNumber, controllerNumber },
        access,
      }),
    staleTime: 60 * 60 * 1000, //1시간
    refetchInterval: 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000, //12시간
  });
};
